import React from 'react'
import styles from './footer.module.css'

function Footer() {
  return (
    <div className={styles.container_footer}>
        Diseñado y desarrollado por Camila Pletscher
    </div>
  )
}

export default Footer